<template lang="html">
    <div>
         <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <div class="container-fluid" style="margin-top: 5%;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-2" v-for="contract in contracts">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <h5 class="card-title">{{contract.distributor.name}}</h5>
                                            <p class="card-text">{{contract.contract_date}} <i class="fas fa-random"></i> {{contract.exp_date}}</p>
                                        </div>
                                        <!-- <div class="col-md-2">
                                            <button class="action text-primary" @click="editForm(contract)"><i
                                                    class="fas fa-edit"></i></button>
                                            <button @click="deleteForm(contract)" v-b-modal.bv-modal-delete
                                                class="action text-danger"><i class="fas fa-trash-alt"></i></button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <b-modal id="bv-modal-delete" ref="my-modal" hide-backdrop hide-footer>
                <template v-slot:modal-title>
                    Delete
                </template>
                <div class="d-block text-center">
                    <p>Are you sure to delete?</p>
                    <small class="text-danger">{{message}}</small>
                </div>
                <b-button class="mt-3 btn-danger" block @click="loactionDelete()">Delete</b-button>
            </b-modal>
            <router-link class="float-filter" to="">
                <i class="fa fa-filter my-float" style="padding: 37%;"></i>
            </router-link> -->
            <router-link class="float" to="contract-create">
                <i class="fa fa-plus my-float" style="padding: 37%;"></i>
            </router-link>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'contract',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                contracts: [],
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                contractListAction: 'contractListAction',
            }),
            async contractList() {
                this.isLoading = true;
                await this.contractListAction().then(res => {
                    if (res.status == "success") {
                        this.contracts = res.data.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            editForm(location) {
            },
            deleteForm(location) {
                this.locationRequest.id = category.id
                this.message = ""
            }
        },
        async mounted() {
            this.contractList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 70px;
        right: 3%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }
    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 140px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }
</style>